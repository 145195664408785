import { Alert } from 'antd'
import React, { useState } from 'react'
import { useGetEventUserQuery, useUpdateEventUserMutation } from '../../api/eventUser.api'
import { useMyContext } from '../../context/context'
import SearchApi from '../../services/api/Search.api'
import UserApi from '../../services/api/User.api'
import Input from '../../shared/form/input'
import { useSteps } from './Steps'

const RepresentedBy = () => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const { setUserId, nextStep, NextButton } = useSteps()
  const { myContext, user, settings, t } = useMyContext()

  const { data: eventUser } = useGetEventUserQuery(myContext)
  const [updateEventUser] = useUpdateEventUserMutation()

  return (
    <div>
      {settings?.FO_MEETING_SHOW_REPRESENTED_BY_HTML && (
        <div style={{ padding: 16 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_SHOW_REPRESENTED_BY_HTML.html }} />
      )}
      <form
        onSubmit={async e => {
          e.preventDefault()
          const { users } = await new SearchApi().get(true, false, email, null)

          let user2
          if (Array.isArray(users) && users.length > 0) {
            user2 = users[0]
          } else {
            const u = await new UserApi().create({ email, firstName, lastName })
            user2 = u
          }

          setUserId(user2.id)
          await Promise.all([
            updateEventUser({
              ...myContext,
              userId: user2.id,
              eventUser: {
                invitedBy: user.id,
                invited: true,
                registeredFrom: 'WEB',
                registeredBy: null,
                representedBy: null,
                responded: false,
                isRemote: false,
                extended: {
                  ...(eventUser?.UserEvent?.extended || {}),
                  PARTICIPATION_IS_REMOTE: false,
                },
              },
            }).unwrap(),
            updateEventUser({
              ...myContext,
              eventUser: {
                representedBy: `${user2.firstName} ${user2.lastName} (${user2.id})`,
                responded: true,
              },
            }).unwrap(),
          ])
          nextStep()
        }}
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        {user?.Authentication?.email === email && <Alert style={{ marginBottom: 10 }} message={t('SAME_EMAIL')} type="error" />}
        <Input
          style={{ marginBottom: 10, width: 400, maxWidth: '100%' }}
          label={t('FIRST_NAME')}
          value={firstName}
          onChange={setFirstName}
          required
        />
        <Input
          style={{ marginBottom: 10, width: 400, maxWidth: '100%' }}
          label={t('LAST_NAME')}
          value={lastName}
          onChange={setLastName}
          required
        />
        <Input
          style={{ marginBottom: 10, width: 400, maxWidth: '100%' }}
          label={t('EMAIL_REPRESENTATIVE')}
          value={email}
          onChange={setEmail}
          type="email"
          required
        />
        <NextButton disabled={user.Authentication.email === email} htmlType="submit" />
      </form>
    </div>
  )
}

export default RepresentedBy
