import React, { useState } from 'react'
import { useMyContext } from '../../context/context'
import EventMessageApi from '../../services/api/EventMessage.api'
import Button from '../../shared/form/button'

const Chat = () => {
  const { settings, t } = useMyContext()
  const [{ message, success, timeOut }, setState] = useState({
    message: '',
    success: false,
    timeOut: null,
  })

  return (
    <div>
      <div
        style={{
          fontSize: 22,
          fontWeight: 700,
          textAlign: 'center',
          marginBottom: 20,
        }}
      >
        {t('QUESTIONS_REACTIONS')}
      </div>
      <div className="field">
        <label className="label is-small">{t('ASK_QUESTION')}</label>
        <div className="control">
          <textarea
            className="textarea is-small"
            placeholder={t('ENTER_HERE')}
            rows="2"
            value={message}
            onChange={event => setState({ message: event.target.value, success: false })}
          />
        </div>
      </div>
      {success && (
        <div
          className="field"
          style={{
            textAlign: 'center',
            fontSize: 22,
            background: settings?.bgPrimaryColor,
            color: settings?.textPrimaryColor,
            padding: '5px 10px',
          }}
        >
          {t('MESSAGE_SEND')}
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          label={t('SEND')}
          onClick={() =>
            new EventMessageApi().create({ message }).then(() =>
              setState({
                message: '',
                success: true,
                timeOut: setTimeout(() => setState(p => ({ ...p, success: false, timeOut: null })), 10000),
              })
            )
          }
        />
      </div>
    </div>
  )
}

export default Chat
