import { Modal } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useCreateEventLogMutation } from '../../api/v2/eventLogs'
import { useMyContext } from '../../context/context'
import useActive from '../../hooks/useActive'
import useHistoryCustom from '../../hooks/useHistoryCustom'
import useLog from '../../hooks/useViewLog'
import useWebsocket from '../../hooks/useWebsocket'
import { IUserEvent } from '../../interfaces/userEvent'
import EventUserPollRightApi from '../../services/api/EventUserPollRight'
import UserEventApi from '../../services/api/UserEvent.api'
import UserEventDayApi from '../../services/api/UserEventDay.api'
import ROUTES from '../../services/routes'
import Chat from './chat'
import Poll from './poll'
import Questions from './Questions'
import Video from './video'
import WordCloudResponse from './WordCloudResponse'

const RightModule = ({ data, right, settings, userEvent }: any) => {
  if (!data) return null
  if (!data.eventLive) return null
  if (settings?.FO_MEETING_MODE_OUT_ACTIVE && !userEvent.isRemote && userEvent.isOut) {
    return (
      <div
        className="live-flex"
        style={{ width: settings?.FO_MEETING_MODULE_LIVE_BELOW ? '100%' : '50%', padding: '20px 40px' }}
        dangerouslySetInnerHTML={{ __html: settings?.FO_MEETING_MODE_OUT_HTML?.html }}
      />
    )
  }
  if (data.eventLive.activeModule === 'poll' && right?.hasRightToVote)
    return (
      <div
        className="live-flex"
        style={{ width: settings?.FO_MEETING_MODULE_LIVE_BELOW ? '100%' : '50%', padding: '20px 40px', fontWeight: 500 }}
      >
        <Poll data={data} />
      </div>
    )
  if (data.eventLive.activeModule === 'question' && data.question)
    return (
      <div
        className="live-flex"
        style={{ width: settings?.FO_MEETING_MODULE_LIVE_BELOW ? '100%' : '50%', padding: '20px 0px', fontWeight: 500 }}
      >
        <Questions data={data} />
      </div>
    )
  if (data.eventLive.activeModule === 'chat')
    return (
      <div
        className="live-flex"
        style={{ width: settings?.FO_MEETING_MODULE_LIVE_BELOW ? '100%' : '50%', padding: '20px 40px', fontWeight: 500 }}
      >
        <Chat />
      </div>
    )
  if (data.eventLive.activeModule === 'wordcloud')
    return (
      <div
        className="live-flex"
        style={{ width: settings?.FO_MEETING_MODULE_LIVE_BELOW ? '100%' : '50%', padding: '20px 40px', fontWeight: 500 }}
      >
        <WordCloudResponse data={data} />
      </div>
    )
  return null
}

const Live = () => {
  const [isStarted, setIsStarted] = useState(false)
  const [userEvent, setUserEvent] = useState<IUserEvent>()
  const [hide, setHide] = useState(false)
  const [right, setRight] = useState<any>(null)
  const [isOut, setIsOut] = useState(false)

  const history = useHistoryCustom()
  const location = useLocation()
  const { isActive } = useActive()

  const [data]: any = useWebsocket()
  const { settings, event, userId, myContext, t } = useMyContext() as any

  const [createEventLog] = useCreateEventLogMutation()

  useLog('VIEW_LIVE')

  const eventDays = event.days

  const updateIsStarted = useCallback(() => {
    const v = settings?.startAt && dayjs(new Date(settings.startAt)).isBefore(dayjs())
    setIsStarted(v)
    return v
  }, [settings])

  useEffect(() => {
    let interval: any
    const cb = () => {
      const v = updateIsStarted()
      if (v && interval) {
        clearInterval(interval)
      }
    }

    interval = setInterval(cb, 10000)
    cb()

    return () => clearInterval(interval)
  }, [updateIsStarted])

  useEffect(() => {
    if (isStarted && myContext) {
      createEventLog({ ...myContext, type: 'IN' })
    }
  }, [createEventLog, isStarted, myContext])

  useEffect(() => {
    let timeout: any

    if (settings?.FO_MEETING_INACTIVE_POPUP) {
      if (!isActive && myContext && !isOut) {
        timeout = setTimeout(() => {
          createEventLog({ ...myContext, type: 'OUT' })
          setIsOut(true)
        }, 60000)
      }
      if (isActive && timeout) {
        clearTimeout(timeout)
      }
      if (isActive && isOut) {
        createEventLog({ ...myContext, type: 'IN' })
        setIsOut(false)
      }
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [createEventLog, isActive, isOut, myContext, settings])

  useEffect(() => {
    if (userId)
      new UserEventApi()
        .getByUserId(userId)
        .then(({ UserEvent }) => {
          if (!UserEvent || (!UserEvent.registered && !UserEvent.responded && window.location.pathname !== '/satisfaction')) {
            history.push(ROUTES.EVENTS_INSCRIPTION)
          }
          return UserEvent
        })
        .then(setUserEvent)
        .then(() => new EventUserPollRightApi(userId).findOne())
        .then(setRight)
  }, [history, settings.FO_MEETING_PARTICIPATON_HIDE, userId])

  const setPresent = useCallback(async () => {
    if (!settings?.startAt) return false

    const date = new Date(settings.startAt)
    if (date > new Date()) return false

    const offset = date.getTimezoneOffset()
    date.setMinutes(date.getMinutes() - offset)
    const today = date.toISOString().substr(0, 10)

    const find = eventDays.find((ed: any) => ed.day === today)
    if (!find) return false

    await Promise.all([
      new UserEventDayApi(find.id).updateOrCreateOne(userId, {
        present: true,
        presentAt: new Date().toLocaleTimeString(),
      }),
      new UserEventApi().updateOrCreateOne(userId, { isRemote: true }),
    ])

    // history.reload()
    return true
  }, [eventDays, settings, userId])

  useEffect(() => {
    if (!userEvent || !settings?.startAt) return
    if (userEvent.present) return

    const interval = setInterval(() => {
      setPresent().then(ok => {
        if (ok) clearInterval(interval)
      })
    }, 10000)
    setPresent().then(ok => {
      if (ok) clearInterval(interval)
    })

    return () => {
      clearInterval(interval)
    }
  }, [setPresent, settings, userEvent, userId])

  if (!userEvent || !userEvent.registered) return <div style={{ textAlign: 'center' }}>{t('NOT_REGISTERED_MESSAGE')}</div>

  if (!settings?.startAt)
    return <div style={{ textAlign: 'center' }}>Vous devez configurer la date l'heure du live youtube dans le back office.</div>

  if (!isStarted && location.search !== '?admin') {
    return (
      <div>
        {settings?.htmlBeforeLive && (
          <div
            style={{
              padding: 20,
              backgroundColor: settings?.bgColorBeforeLive,
            }}
            dangerouslySetInnerHTML={{
              __html: settings.htmlBeforeLive.html || settings.htmlBeforeLive,
            }}
          />
        )}
      </div>
    )
  }

  return (
    <div>
      <Modal title="Etes-vous toujours avec nous ?" visible={!isActive && settings?.FO_MEETING_INACTIVE_POPUP} footer={null}>
        <div>{t('LIVE_NOT_HERE_MESSAGE')}</div>
      </Modal>
      {settings?.htmlLiveTop && (
        <div
          style={{ padding: 20, backgroundColor: settings?.bgColorLiveTop }}
          dangerouslySetInnerHTML={{ __html: settings.htmlLiveTop.html || settings.htmlLiveTop }}
        />
      )}
      {/* <Button
        style={{ position: 'fixed', bottom: 0, zIndex: 100 }}
        label={hide ? 'Afficher la vidéo' : 'Cacher la vidéo'}
        onClick={() => setHide(prev => !prev)}
      /> */}
      <div
        style={{
          display: 'flex',
          flexDirection: settings?.FO_MEETING_MODULE_LIVE_BELOW ? 'column' : 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'center',
          padding: '15px 0',
        }}
      >
        {!settings?.hideVideoLive && (userEvent.isRemote || !userEvent.present) && <Video hide={hide} />}
        <RightModule data={data} right={right} settings={settings} userEvent={userEvent} />
      </div>
      {settings?.htmlLiveBottom && (
        <div
          style={{ padding: 20, backgroundColor: settings?.bgColorLiveBottom }}
          dangerouslySetInnerHTML={{
            __html: settings.htmlLiveBottom.html || settings.htmlLiveBottom,
          }}
        />
      )}
    </div>
  )
}

export default Live
