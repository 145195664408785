import React, { useState } from 'react'
import { useMyContext } from '../../context/context'
import WordCloudApi from '../../services/api/WordCloud'
import Button from '../../shared/form/button'
import MessageColor from '../../shared/MessageColor'

const WordCloudResponse = ({ data }) => {
  const { settings, t } = useMyContext()
  const [{ message, success, error }, setState] = useState({
    message: '',
    success: false,
    error: null,
  })

  const { eventLive, wordCloud } = data

  if (wordCloud.isClose)
    return (
      <div
        style={{
          textAlign: 'center',
          fontSize: 22,
          background: settings?.bgPrimaryColor,
          padding: '5px 10px',
          marginBottom: 15,
        }}
      >
        Le temps imparti est écoulé, il n’est plus possible de répondre
      </div>
    )

  return (
    <div>
      <div
        style={{
          fontSize: 22,
          fontWeight: 700,
          textAlign: 'center',
          marginBottom: 20,
        }}
      >
        {wordCloud.content}
      </div>
      <div className="field">
        <label className="label is-small">Ecrivez un mot :</label>
        <div className="control">
          <textarea
            className="textarea is-small"
            placeholder={t('ENTER_HERE')}
            rows="2"
            value={message}
            onChange={event => setState({ message: event.target.value, success: false })}
          />
        </div>
      </div>

      {success && <MessageColor>Votre mot a bien été pris en compte.</MessageColor>}

      {error && (
        <article className="message is-danger field" style={{ textAlign: 'center' }}>
          <div className="message-body">
            <strong>{error.message}</strong>
          </div>
        </article>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          label={t('SEND')}
          onClick={() =>
            new WordCloudApi()
              .createResponse(eventLive.activeWordCloudId, message)
              .then(() => setState({ message: '', success: true }))
              .catch(err => setState(prev => ({ ...prev, success: false, error: err })))
              .finally(() => setTimeout(() => setState(s => ({ ...s, success: false, error: null })), 5000))
          }
        />
      </div>
    </div>
  )
}

export default WordCloudResponse
