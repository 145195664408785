import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useGetContextQuery } from '../api/api'

const useSettingsBo = () => {
  const { context } = useParams()

  const { data } = useGetContextQuery(context)

  const extended = useMemo(() => data?.settingsBO || {}, [data])

  const settings = useMemo(
    () => ({
      ...extended,
      LANGUAGES_SHOW: data && extended[`EVENT_${data.id}_LANGUAGES_SHOW`],
      LANGUAGE_DEFAULT: data && extended[`EVENT_${data.id}_LANGUAGE_DEFAULT`],
    }),
    [data, extended]
  )

  return settings
}

export default useSettingsBo
