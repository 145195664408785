import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMyContext } from '../context/context'
import { setLoading } from '../redux/loading'
import UserEventApi from '../services/api/UserEvent.api'
import UserEventDayApi from '../services/api/UserEventDay.api'
import Button from '../shared/form/button'
import Success from '../shared/success'

const Message = () => {
  const dispatch = useDispatch()
  const { user, event, t } = useMyContext() as any

  const ref = useRef<HTMLTextAreaElement>(null)
  const [message, setMessage] = useState<string | undefined>(undefined)
  const [success, setSuccess] = useState<boolean>(false)

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!message) return

    setSuccess(false)
    dispatch(setLoading(true))
    await new UserEventApi().updateOrCreateOne(user.id, { message })
    setSuccess(true)
    dispatch(setLoading(false))
  }

  const setNegativeResponse = useCallback(async () => {
    dispatch(setLoading(true))
    await new UserEventApi().updateOrCreateOne(user.id, { responded: true })
    const promises = [
      ...event.days.map((ed: any) =>
        new UserEventDayApi(ed.id).updateOrCreateOne(user.id, {
          registered: false,
          registeredAt: null,
        })
      ),
    ]
    await Promise.all(promises)
    dispatch(setLoading(false))
  }, [dispatch, event.days, user.id])

  useEffect(() => {
    if (ref.current) ref.current.focus()
    if (user && event) setNegativeResponse()
  }, [event, setNegativeResponse, user])

  return (
    <div className="form">
      <Success isSuccess={success} message="Merci pour votre message" />
      <form onSubmit={onSubmit} className="field">
        <div className="field">
          <label className="label">
            Pourriez-vous nous communiquer la raison de votre absence ?<br />
            Si toutefois vous changez d'avis n'hésitez pas à demander votre badge
          </label>
          <p className="control">
            <textarea
              className="textarea"
              value={message}
              onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) => setMessage(target.value)}
              ref={ref}
              required
            />
          </p>
        </div>

        <Button style={{ width: '100%' }} label={t('SEND')} submit />
      </form>
    </div>
  )
}

export default Message
