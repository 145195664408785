import { useMemo } from 'react'
import { useGetLanguagesQuery } from '../api/v2/languages'
import useSettingsBo from './useSettingsBO'

export default function useLanguages() {
  const settings = useSettingsBo()

  const { data } = useGetLanguagesQuery()

  const languages = useMemo(() => data?.filter(f => settings?.LANGUAGES_SHOW?.find(ff => ff === f.key) || f.key === 'FR'), [data, settings])

  return languages
}
